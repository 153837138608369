<template>
  <div class="vue-comments">
    <div class="row">
      <div class="col-md-7 all-comments">
        <div v-for="comment in comments" :key="comment.id" class="card mb-3">
          <div class="card-body">
            <p>{{ comment.comment }}</p>
            <div class="reactions">
              <button
                @click="isLoggedIn() && reactToComment(comment.id, 'like')"
                :class="{'is-active': hasUserReacted('like', comment)}"
                class="reaction-btn"
              >
                <span class="emoji">👍</span>
                <span class="text">Me gusta ({{ comment.reactions.like.total }})</span>
              </button>
              <button
                @click="isLoggedIn() && reactToComment(comment.id, 'dislike')"
                :class="{'is-active': hasUserReacted('dislike', comment)}"
                class="reaction-btn"
              >
                <span class="emoji">👎</span>
                <span class="text">No me gusta ({{ comment.reactions.dislike.total }})</span>
              </button>
              <button
                @click="isLoggedIn() && reactToComment(comment.id, 'heart')"
                :class="{'is-active': hasUserReacted('heart', comment)}"
                class="reaction-btn"
              >
                <span class="emoji">❤️</span>
                <span class="text">Me encanta ({{ comment.reactions.heart.total }})</span>
              </button>
              <div class="total-reactions">
                <span class="text">Total: {{ parseInt(comment.reactions.like.total) + parseInt(comment.reactions.dislike.total) + parseInt(comment.reactions.heart.total) }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-2">
                <button @click="isLoggedIn() && showReplyInput(comment.id)" class="btn btn-sm btn-primary">
                  Responder
                </button>
              </div>
              <div class="col-md-5" v-if="comment.showReply">
                <textarea class="form-control" v-model="comment.replyText" placeholder="Escribe una respuesta..." required></textarea>
              </div>
              <div class="col-md-2" v-if="comment.showReply">
                <button @click="submitReply(comment.id)" class="btn btn-sm btn-success">
                  Enviar
                </button>
              </div>
            </div>
            <br />
            <button @click="toggleReplies(comment.id)" class="btn btn-sm btn-outline-secondary">
              Ver respuestas
            </button>
            <div v-if="comment.showReplies" class="mt-2">
              <template v-if="comment.replies && comment.replies.length > 0">
                <div v-for="reply in comment.replies" :key="reply.id" class="card mb-2">
                  <div class="card-body">
                    {{ reply.comment }}
                  </div>
                </div>
              </template>
              <div v-else class="text-muted">{{ comment.noRepliesMessage || 'Cargando respuestas...' }}</div>
            </div>
          </div>
        </div>
        <a v-if="lastVisibleId" @click="loadNextPage" class="load-more-comments">Más comentarios</a>
      </div>
      <div class="col-md-1"></div>
      <div class="col-md-3">
        <div class="card">
          <div class="card-body">
            Publicidad aquí
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PNotify from 'pnotify/dist/es/PNotify';

export default {
  data() {
    return {
      comments: [],
      count: 0,
      pageSize: 7,
      lastVisibleId: null,
      authToken: 'your-token',
      userName: 'your-username',
    };
  },
  created() {
    this.fetchComments();
  },
  mounted() {
    window.addEventListener('commentSent', (event) => {
      this.userName = event.detail.userId;
      this.authToken = event.detail.token;
      this.fetchComments();
    });
  },
  methods: {
    isLoggedIn() {
      if (this.authToken !== 'your-token') {
        return true;
      } else {
        this.toggleLogin();
        return false;
      }
    },
    hasUserReacted(reactionType, comment) {
      const currentUserId = this.userName;
      return comment.reactions[reactionType].users.some(user => user === currentUserId);
    },
    showReplyInput(commentId) {
      const comment = this.comments.find(c => c.id === commentId);
      if (comment) {
        comment.showReply = !comment.showReply;
        if (comment.showReply) {
          comment.replyText = '';
        }
      }
    },
    async submitReply(commentId) {
      const comment = this.comments.find(c => c.id === commentId);
      if (comment && comment.replyText.trim()) {
        await this.sendReplyToAPI(commentId, comment.replyText);
        comment.showReply = false;
        comment.replyText = '';
      }
    },
    async sendReplyToAPI(commentId, replyText) {
      await fetch(`${process.env.VUE_APP_BASE_URL_FIRESTORE}/comments-parent`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer ' + this.authToken
          },
          mode: 'cors',
          body: JSON.stringify({
            userId: this.userName,
            comment: replyText,
            parentComment: commentId,
            report: false,
          }),
        }).then((response) => {
          if (response.ok) {
            this.toggleShow();
            this.toggleReplies(commentId);
            this.triggerCustomEvent(response);
          } else {
            // localStorage.removeItem('authToken');
            this.toggleLogin();
            throw new Error('Network response was not ok ' + response.text().then(text => {return text;}));
          }
        })
        .catch(() => this.toggleApiError());
    },
    async fetchComments () {
      await fetch(`${process.env.VUE_APP_BASE_URL_FIRESTORE_FREE}/comments`, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        mode: 'cors',
      }).then(response => {
          if (!response.ok) {
            if(response.status === 404) {
              this.comments = [];
              return;
            }
            throw new Error('Network response was not ok ' + response.statusText);
          }
          return response.json();
        })
        .then(data => {
          this.comments = data.comments;
          this.lastVisibleId = data.lastVisible;
        })
        .catch(error => {
          console.error('Hubo un error al obtener los comentarios:', error);
        });
    },
    async reactToComment(commentId, reactionType) {
        await fetch(`${process.env.VUE_APP_BASE_URL_FIRESTORE}/comments/${commentId}/reactions`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer ' + this.authToken
          },
          mode: 'cors',
          body: JSON.stringify({
            userId: this.userName,
            reaction: reactionType,
            lastVisibleId: this.lastVisibleId
          }),
        }).then(response => {
            if(response.ok) {
              this.fetchComments();
              this.triggerCustomEvent(response);
            } else {
              // localStorage.removeItem('authToken');
              this.toggleLogin();
              throw new Error('Network response was not ok ' + response.text().then(text => {return text;}));
            }
          })
          .catch(error => {
            console.error('Hubo un error al registrar la reacción:', error);
          });
    },
    async toggleReplies(commentId) {
      const comment = this.comments.find(c => c.id === commentId);
      if (comment) {
        comment.showReplies = !comment.showReplies;
        if (comment.showReplies) {
          await fetch(`${process.env.VUE_APP_BASE_URL_FIRESTORE_FREE}/comments-parent`,{
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
            },
            mode: 'cors',
            body: JSON.stringify({
              commentId: commentId,
            }),
          }).then(response =>{
            if (!response.ok) {
              if(response.status === 404){
                comment.noRepliesMessage = "No hay datos";
                return;
              } else {
                throw new Error('Network response was not ok ' + response.statusText);
              }
            }
            return response.json();
          }).then((data) => {
            comment.replies = data;
            comment.showReplies = true;
          }).catch(error => {
            console.error('Hubo un error al obtener las respuestas:', error);
          });
        }
      }
    },
    async loadNextPage() {
      await fetch(`${process.env.VUE_APP_BASE_URL_FIRESTORE_FREE}/comments/next-page`,{
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        mode: 'cors',
        body: JSON.stringify({
          lastVisibleId: this.lastVisibleId,
          pageSize: this.pageSize,
        }),
      }).then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok ' + response.statusText);
        }
        return response.json();
      }).then(data => {
        this.comments = [...this.comments, ...data.comments];
        this.lastVisibleId = data.lastVisible;
      }).catch(error => {
        console.error('Hubo un error al obtener los comentarios:', error);
      })
    },
    triggerCustomEvent(response) {
      const event = new CustomEvent('reactionSent', response);
      window.dispatchEvent(event);
    },
    toggleLogin() {
        if (typeof window.stackBottomRight === 'undefined') {
            window.stackBottomRight = {
                dir1: 'left',
                dir2: 'up',
                firstpos1: 25,
                firstpos2: 25,
                push: 'top'
            };
        }
        PNotify.success({
            title: 'Iniciar Sessión',
            text: "Para comentar, responder o reaccionar debes iniciar sessión :D",
            stack: window.stackBottomRight,
            delay:3000
        });
    },
    toggleShow() {
        if (typeof window.stackBottomRight === 'undefined') {
            window.stackBottomRight = {
                dir1: 'left',
                dir2: 'up',
                firstpos1: 25,
                firstpos2: 25,
                push: 'top'
            };
        }
        PNotify.success({
            title: 'Enviado!',
            text: "Gracias por responder ha este comentario :D",
            stack: window.stackBottomRight,
            delay:3000
        });
    },
    toggleApiError(){
        if (typeof window.stackBottomRight === 'undefined') {
            window.stackBottomRight = {
                dir1: 'left',
                dir2: 'up',
                firstpos1: 25,
                firstpos2: 25,
                push: 'top'
            };
        }
        PNotify.error({
            title: 'Yara mano',
            text: "No se pudo enviar comentario :(",
            stack: window.stackBottomRight,
            delay:3000
        });
    },
  },
  beforeUnmount() {
    window.removeEventListener('commentSent', (event) => {
        this.userName = event.detail.userId;
        this.authToken = event.detail.token;
        this.fetchComments();
      });
  }
};
</script>